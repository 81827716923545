import { isAfter } from 'date-fns';

import { creditRatings } from '~/components/Shared/CreditRatingSingleSelect';
import { Text } from '~/ui';
import {
  getCompanyProbabilityOfDefault,
  getCompanyCumulativeProbabilityOfDefault,
  getCompanyProbabilityOfDefaultAdj,
  getCompanyRating,
  getCompanyRatingAdj,
} from '~/utils/companies';
import { createTenor, formatDateString } from '~/utils/dates';
import { getSheetData } from '~/utils/excel';
import { displayNumber, getNote } from '~/utils/strings';
import { getVisibleColumns } from '~/utils/tables';
import { reportStatusColors, lenderOrGuarantorTypeEnum } from '~/enums';

export function getGuaranteeData(guarantee, userInfo) {
  const {
    amount,
    currency,
    editable,
    guarantor,
    id,
    isPortfolio,
    issueDate,
    principal,
    report,
    status,
    terminationDate,
    paymentFrequency,
    createdAt,
    updatedAt,
    deletedAt,
    isThirdParty,
    note,
  } = guarantee;
  const statusPossibleExpire = isAfter(new Date(), new Date(terminationDate)) ? 'Expired' : status;

  return {
    id,
    guarantorName: guarantor?.name,
    principalName: principal?.name,
    currency,
    amount: displayNumber(amount, userInfo.decimalPoint),
    guarantorRatingStandalone: getCompanyRating(guarantor),
    guarantorProbabilityOfDefault: getCompanyProbabilityOfDefault(guarantor, userInfo),
    guarantorRatingImplicitSupportAdj: getCompanyRatingAdj(guarantor),
    principalRatingStandalone: getCompanyRating(principal),
    principalProbabilityOfDefault: getCompanyProbabilityOfDefault(principal, userInfo),
    principalCumulativeProbabilityOfDefault: getCompanyCumulativeProbabilityOfDefault(principal, userInfo),
    principalRatingImplicitSupportAdj: getCompanyRatingAdj(principal),
    principalProbabilityOfDefaultImplicitSupportAdj: getCompanyProbabilityOfDefaultAdj(principal, userInfo),
    issueDate: formatDateString(issueDate, userInfo.dateFormat),
    terminationDate: formatDateString(terminationDate, userInfo.dateFormat),
    period: displayNumber(createTenor(issueDate, terminationDate).toFixed(2), userInfo.decimalPoint),
    isPortfolio,
    createdAt: formatDateString(createdAt, userInfo.dateFormat),
    updatedAt: formatDateString(updatedAt, userInfo.dateFormat),
    deletedAt: formatDateString(deletedAt, userInfo.dateFormat) || 'n/a',
    editable,
    status: statusPossibleExpire,
    finalInterestRate: displayNumber(report?.finalInterestRate, userInfo.decimalPoint) + '%',
    report,
    paymentFrequency,
    lowerBound: report?.lowerBound ? displayNumber(report.lowerBound, userInfo.decimalPoint) + '%' : '-',
    midPoint: report?.midPoint ? displayNumber(report.midPoint, userInfo.decimalPoint) + '%' : '-',
    upperBound: report?.upperBound ? displayNumber(report.upperBound, userInfo.decimalPoint) + '%' : '-',
    guarantorType: isThirdParty ? lenderOrGuarantorTypeEnum.THIRD_PARTY : lenderOrGuarantorTypeEnum.INTERCOMPANY,
    note: getNote(note),
  };
}

export function getGuaranteesData(data = [], userInfo) {
  return data.map((item) => getGuaranteeData(item, userInfo));
}

const columns = [
  { label: 'Guarantor', sortBy: 'guarantorName', value: 'guarantorName', width: 250 },
  { label: 'Principal', sortBy: 'principalName', value: 'principalName', width: 250 },
  { label: 'Currency', sortBy: 'currency', value: 'currency' },
  { label: 'Amount', sortBy: 'amount', value: 'amount', justifyContent: 'flex-end' },
  { label: 'Issue date', sortBy: 'issueDate', value: 'issueDate' },
  { label: 'Termination date', sortBy: 'terminationDate', value: 'terminationDate' },
  { label: 'Fee payment frequency', sortBy: 'paymentFrequency', value: 'paymentFrequency' },
  {
    label: 'Guarantor rating standalone',
    sortArray: creditRatings,
    sortBy: 'guarantorRatingStandalone',
    sortType: 'array',
    value: 'guarantorRatingStandalone',
  },
  {
    label: 'Guarantor rating implicit support adj.',
    sortArray: creditRatings,
    sortBy: 'guarantorRatingImplicitSupportAdj',
    sortType: 'array',
    value: 'guarantorRatingImplicitSupportAdj',
  },
  {
    label: 'Principal rating standalone',
    sortArray: creditRatings,
    sortBy: 'principalRatingStandalone',
    sortType: 'array',
    value: 'principalRatingStandalone',
  },
  {
    label: 'Principal rating implicit support adj.',
    sortArray: creditRatings,
    sortBy: 'principalRatingImplicitSupportAdj',
    sortType: 'array',
    value: 'principalRatingImplicitSupportAdj',
  },
  { label: 'Period (yr.)', sortBy: 'period', value: 'period' },
  { label: 'Created', sortBy: 'createdAt', value: 'createdAt' },
  { label: 'Updated', sortBy: 'updatedAt', value: 'updatedAt' },
  { label: 'Deleted', sortBy: 'deletedAt', value: 'deletedAt' },
  {
    label: 'Status',
    sortBy: 'status',
    value: 'status',
    renderCustomCell: (item) => {
      return (
        <Text color={reportStatusColors[item.status]} variant="m-spaced">
          {item.status}
        </Text>
      );
    },
  },
  { label: 'Rate', sortBy: 'finalInterestRate', value: 'finalInterestRate', justifyContent: 'flex-end' },
  { label: 'Lower bound', sortBy: 'lowerBound', value: 'lowerBound', justifyContent: 'flex-end' },
  { label: 'Base', sortBy: 'midPoint', value: 'midPoint', justifyContent: 'flex-end' },
  { label: 'Upper bound', sortBy: 'upperBound', value: 'upperBound', justifyContent: 'flex-end' },
  { label: 'Guarantor Type', sortBy: 'guarantorType', value: 'guarantorType' },
  { label: 'Note', sortBy: 'note', value: 'note' },
];

export function getGuaranteesSheetData(data = [], visibleColumns, userInfo) {
  return getSheetData({ data, columns, visibleColumns, userInfo, getColumnData: getGuaranteeData });
}

export function getGuaranteesColumns(visibleColumns) {
  return getVisibleColumns({ columns, visibleColumns });
}
